import React, { useState } from 'react';


const EpisodeNotes = () => {
  const styles = {
    container: {
      marginTop: '48px',
      padding: '32px',
      backgroundColor: '#f9fafb',
      borderRadius: '4px',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 600,
      marginBottom: '24px',
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    listItem: {
      marginBottom: '16px',
      paddingLeft: '24px',
      position: 'relative',
      fontSize: '1rem',
      color: '#374151',
    },
    link: {
      color: '#f97316',
      textDecoration: 'none',
      transition: 'color 0.2s',
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Episode Notes & References</h3>
      <ul style={styles.list}>
        <li style={styles.listItem}>• UN Security Council Resolution 2728 - <a href="#" style={styles.link}>Read More</a></li>
        <li style={styles.listItem}>• Dr. Schueftan's latest book: "Middle East Dynamics" - <a href="#" style={styles.link}>Purchase Here</a></li>
        <li style={styles.listItem}>• Referenced Article: "Regional Power Shifts in 2025" - <a href="#" style={styles.link}>Full Article</a></li>
      </ul>
    </div>
  );
};


export default EpisodeNotes; 