import React, { useState } from 'react';
import { HeartHandshake, BadgeDollarSign, Repeat, CreditCard, Bitcoin, Coins } from 'lucide-react';

const DonatePage = () => {
  const [isMonthly, setIsMonthly] = useState(false);
  const [selectedTier, setSelectedTier] = useState(null);

  const styles = {
    container: {
      minHeight: '100vh',
      backgroundColor: 'white',
      padding: 'clamp(2rem, 5vw, 4rem) clamp(1rem, 3vw, 1.5rem)',
      overflowX: 'hidden', // Prevent horizontal scroll
    },
    content: {
      maxWidth: '768px',
      margin: '0 auto',
      width: '100%', // Ensure content takes available width
    },
    header: {
      textAlign: 'center',
      marginBottom: 'clamp(2rem, 5vw, 4rem)',
    },
    title: {
      fontSize: 'clamp(1.75rem, 4vw, 2.5rem)',
      fontWeight: '700',
      marginBottom: '1rem',
    },
    subtitle: {
      color: '#4b5563',
      maxWidth: '512px',
      margin: '0 auto',
      lineHeight: '1.5',
      fontSize: 'clamp(0.875rem, 2vw, 1rem)',
      padding: '0 1rem',
    },
    frequencyToggle: {
      display: 'flex',
      justifyContent: 'center',
      gap: '1rem',
      marginBottom: 'clamp(2rem, 4vw, 3rem)',
      padding: '0 1rem',
    },
    toggleButton: {
      padding: '0.5rem 1.25rem',
      borderRadius: '1.5rem',
      border: '1px solid #e5e7eb',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: 'clamp(0.875rem, 2vw, 1rem)',
      whiteSpace: 'nowrap',
    },
    activeToggle: {
      backgroundColor: '#f97316',
      color: 'white',
      borderColor: '#f97316',
    },
    tiersContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginBottom: 'clamp(2rem, 4vw, 3rem)',
      width: '100%',
    },
    tierButton: {
      width: '100%',
      textAlign: 'left',
      padding: '1.5rem',
      borderRadius: '0.5rem',
      border: '1px solid #f3f4f6',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    selectedTier: {
      borderColor: '#f97316',
      backgroundColor: '#fff7ed',
    },
    tierContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '1rem', // Ensure spacing between elements
      flexWrap: 'wrap', // Allow wrapping on very small screens
    },
    tierInfo: {
      flex: '1',
      minWidth: '200px', // Ensure readable width
    },
    tierTitle: {
      fontSize: 'clamp(1rem, 2.5vw, 1.125rem)',
      fontWeight: '500',
      marginBottom: '0.25rem',
    },
    tierDescription: {
      fontSize: 'clamp(0.75rem, 2vw, 0.875rem)',
      color: '#6b7280',
    },
    tierAmount: {
      fontSize: 'clamp(1.125rem, 2.5vw, 1.25rem)',
      fontWeight: '600',
      color: '#f97316',
      whiteSpace: 'nowrap',
    },
    monthlyLabel: {
      fontSize: 'clamp(0.625rem, 1.5vw, 0.75rem)',
      color: '#6b7280',
    },
    customAmount: {
      marginBottom: 'clamp(2rem, 4vw, 3rem)',
      width: '100%',
      maxWidth: '400px',
      margin: '0 auto',
    },
    inputContainer: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: '100%',
      padding: '0.75rem 0.75rem 0.75rem 2rem',
      borderRadius: '0.5rem',
      border: '1px solid #e5e7eb',
      fontSize: 'clamp(0.875rem, 2vw, 1rem)',
      outline: 'none',
      backgroundColor: '#f9fafb',
      transition: 'all 0.2s ease',
      '&:focus': {
        borderColor: '#f97316',
        backgroundColor: 'white',
      },
    },
    currencySymbol: {
      position: 'absolute',
      left: '0.75rem',
      top: '50%',
      transform: 'translateY(-50%)',
      color: '#9ca3af',
    },
    paymentMethods: {
      marginBottom: 'clamp(2rem, 4vw, 3rem)',
      width: '100%',
    },
    paymentLabel: {
      fontSize: 'clamp(0.75rem, 2vw, 0.875rem)',
      color: '#6b7280',
      marginBottom: '1rem',
    },
    paymentGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(80px, 1fr))',
      gap: '1rem',
      width: '100%',
    },
    paymentButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.5rem',
      padding: '1rem',
      borderRadius: '0.5rem',
      border: '1px solid #f3f4f6',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
      width: '100%',
    },
    paymentIcon: {
      width: '1.25rem',
      height: '1.25rem',
      color: '#6b7280',
    },
    donateButton: {
      width: '100%',
      padding: '1rem',
      backgroundColor: '#f97316',
      color: 'white',
      border: 'none',
      borderRadius: '0.5rem',
      fontSize: 'clamp(0.875rem, 2vw, 1rem)',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
    },
    footer: {
      textAlign: 'center',
      fontSize: 'clamp(0.75rem, 2vw, 0.875rem)',
      color: '#6b7280',
      marginTop: '2rem',
      padding: '0 1rem',
    },
  };
  const tiers = [
    {
      title: "Supporter",
      amount: 25,
      description: "Help cover basic production costs"
    },
    {
      title: "Partner",
      amount: 100,
      description: "Support our field research"
    },
    {
      title: "Patron",
      amount: 500,
      description: "Enable investigative reporting"
    }
  ];

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <div style={styles.header}>
          <h1 style={styles.title}>Support Our Work</h1>
          <p style={styles.subtitle}>
            Your contribution helps us maintain independence and continue producing in-depth analysis
          </p>
        </div>

        <div style={styles.frequencyToggle}>
          <button 
            style={{
              ...styles.toggleButton,
              ...(isMonthly ? {} : styles.activeToggle)
            }}
            onClick={() => setIsMonthly(false)}
          >
            One-time
          </button>
          <button 
            style={{
              ...styles.toggleButton,
              ...(isMonthly ? styles.activeToggle : {})
            }}
            onClick={() => setIsMonthly(true)}
          >
            Monthly <Repeat size={16} />
          </button>
        </div>

        <div style={styles.tiersContainer}>
          {tiers.map((tier, index) => (
            <button
              key={index}
              style={{
                ...styles.tierButton,
                ...(selectedTier === index ? styles.selectedTier : {})
              }}
              onClick={() => setSelectedTier(index)}
              onMouseEnter={(e) => {
                if (selectedTier !== index) {
                  e.currentTarget.style.borderColor = '#fda172';
                }
              }}
              onMouseLeave={(e) => {
                if (selectedTier !== index) {
                  e.currentTarget.style.borderColor = '#f3f4f6';
                }
              }}
            >
              <div style={styles.tierContent}>
                <div style={styles.tierInfo}>
                  <h3 style={styles.tierTitle}>{tier.title}</h3>
                  <p style={styles.tierDescription}>{tier.description}</p>
                </div>
                <div style={styles.tierAmount}>
                  ${tier.amount}
                  {isMonthly && <span style={styles.monthlyLabel}>/mo</span>}
                </div>
              </div>
            </button>
          ))}
        </div>

        <div style={styles.customAmount}>
          <div style={styles.inputContainer}>
            <span style={styles.currencySymbol}>$</span>
            <input
              type="number"
              placeholder="Custom amount"
              style={styles.input}
              min="1"
            />
          </div>
        </div>

        <div style={styles.paymentMethods}>
          <h3 style={styles.paymentLabel}>Payment method</h3>
          <div style={styles.paymentGrid}>
            {[
              { icon: <CreditCard />, label: "Card" },
              { icon: <Coins />, label: "PayPal" },
              { icon: <Bitcoin />, label: "Crypto" }
            ].map((method, index) => (
              <button
                key={index}
                style={styles.paymentButton}
                onMouseEnter={(e) => {
                  e.currentTarget.style.backgroundColor = '#f9fafb';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.backgroundColor = 'white';
                }}
              >
                {React.cloneElement(method.icon, { style: styles.paymentIcon })}
                <span style={styles.paymentLabel}>{method.label}</span>
              </button>
            ))}
          </div>
        </div>

        <button
          style={styles.donateButton}
          onMouseEnter={(e) => {
            e.currentTarget.style.backgroundColor = '#ea580c';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.backgroundColor = '#f97316';
          }}
        >
          Complete Donation
        </button>

        <p style={styles.footer}>
          All donations are tax-deductible through our 501(c)(3) fiscal sponsor
        </p>
      </div>
    </div>
  );
};

export default DonatePage;