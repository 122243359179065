import React, { useState } from 'react';
import { ArrowRight, Play } from 'lucide-react';

const AllEpisodesPage = () => {
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundColor: 'white',
      padding: '64px 24px',
    },
    content: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    header: {
      marginBottom: '64px',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '16px',
    },
    filters: {
      display: 'flex',
      gap: '16px',
      justifyContent: 'center',
      marginBottom: '48px',
      flexWrap: 'wrap',
    },
    filterButton: {
      padding: '8px 16px',
      borderRadius: '20px',
      border: '1px solid #e5e7eb',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    episodeGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '32px',
      marginBottom: '64px',
    },
    episodeCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      overflow: 'hidden',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      border: '1px solid #f3f4f6',
    },
    episodeImage: {
      width: '100%',
      height: '200px',
      backgroundColor: '#f3f4f6',
      position: 'relative',
    },
    playButton: {
      position: 'absolute',
      bottom: '16px',
      right: '16px',
      backgroundColor: '#f97316',
      color: 'white',
      borderRadius: '50%',
      width: '40px',
      height: '40px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
    },
    episodeContent: {
      padding: '24px',
    },
    episodeNumber: {
      color: '#6b7280',
      fontSize: '0.875rem',
      marginBottom: '8px',
    },
    episodeTitle: {
      fontSize: '1.125rem',
      fontWeight: 600,
      marginBottom: '12px',
      lineHeight: 1.3,
    },
    episodeDate: {
      color: '#6b7280',
      fontSize: '0.875rem',
    },
    pagination: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      marginTop: '48px',
    },
    pageButton: {
      padding: '8px 16px',
      borderRadius: '6px',
      border: '1px solid #e5e7eb',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    activePage: {
      backgroundColor: '#f97316',
      color: 'white',
      borderColor: '#f97316',
    },
    bookAuthor: {
      color: '#6b7280',
      fontSize: '0.875rem',
      marginBottom: '16px',
    },
  };

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 5;
  const filters = ['All', 'Recent', 'Popular', 'Interviews', 'Analysis'];
  const episodes = Array(12).fill().map((_, i) => ({
    id: i + 1,
    title: 'Envirohealth podcast',
    number: `Episode ${256 + i}`,
    date: `January ${i + 1}, 2025`,
    duration: '45 min',
  }));

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <header style={styles.header}>
          <h1 style={styles.title}>All Episodes</h1>
          <p style={{ ...styles.bookAuthor, textAlign: 'center' }}>
            All episodes exploring the intersection of science, technology, and environmental health.
          </p>
          <div style={styles.filters}>
            {filters.map(filter => (
              <button
                key={filter}
                style={styles.filterButton}
                onMouseEnter={e => {
                  e.target.style.backgroundColor = '#f9fafb';
                  e.target.style.borderColor = '#f3f4f6';
                }}
                onMouseLeave={e => {
                  e.target.style.backgroundColor = 'white';
                  e.target.style.borderColor = '#e5e7eb';
                }}
              >
                {filter}
              </button>
            ))}
          </div>
        </header>

        <div style={styles.episodeGrid}>
          {episodes.map(episode => (
            <div
              key={episode.id}
              style={styles.episodeCard}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'translateY(-4px)';
                e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              <div style={styles.episodeImage}>
                <div
                  style={styles.playButton}
                  onMouseEnter={e => (e.target.style.backgroundColor = '#ea580c')}
                  onMouseLeave={e => (e.target.style.backgroundColor = '#f97316')}
                >
                  <Play size={20} />
                </div>
              </div>
              <div style={styles.episodeContent}>
                <div style={styles.episodeNumber}>{episode.number}</div>
                <h3 style={styles.episodeTitle}>{episode.title}</h3>
                <div style={styles.episodeDate}>{episode.date}</div>
                <div style={styles.episodeDate}>{episode.duration}</div>
              </div>
            </div>
          ))}
        </div>

        <div style={styles.pagination}>
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              style={{
                ...styles.pageButton,
                ...(currentPage === i + 1 && styles.activePage),
              }}
              onClick={() => setCurrentPage(i + 1)}
              onMouseEnter={e => {
                if (currentPage !== i + 1) {
                  e.target.style.backgroundColor = '#f9fafb';
                }
              }}
              onMouseLeave={e => {
                if (currentPage !== i + 1) {
                  e.target.style.backgroundColor = 'white';
                }
              }}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AllEpisodesPage;