import React, { useState, useEffect } from 'react';
import { Menu, X, ChevronRight } from 'lucide-react';
import { Link } from 'react-router-dom';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const styles = {
    nav: {
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      borderBottom: '1px solid #f3f4f6',
      zIndex: 1000,
    },
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '24px 32px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    logo: {
      fontSize: '1.25rem',
      fontWeight: 600,
      letterSpacing: '-0.025em',
      color: '#111827',
      textDecoration: 'none',
    },
    menuDesktop: {
      display: isMobile ? 'none' : 'flex',
      alignItems: 'center',
      gap: '48px',
    },
    menuMobile: {
      display: isMobile && isMenuOpen ? 'flex' : 'none',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'white',
      zIndex: 1001,
    },
    mobileHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '24px 32px',
      borderBottom: '1px solid #f3f4f6',
    },
    mobileMenuItems: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
    },
    mobileMenuItem: {
      padding: '24px 32px',
      borderBottom: '1px solid #f3f4f6',
      fontSize: '1.5rem',
      color: '#111827',
      textDecoration: 'none',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    linkContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '32px',
    },
    link: {
      fontSize: '0.875rem',
      fontWeight: 500,
      color: '#111827',
      textDecoration: 'none',
      transition: 'color 0.2s ease',
      cursor: 'pointer',
    },
    actionsContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: '24px',
    },
    menuToggle: {
      display: isMobile ? 'block' : 'none',
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
    },
    subscribeButton: {
      backgroundColor: 'black',
      color: 'white',
      padding: '8px 16px',
      fontSize: '0.875rem',
      fontWeight: 500,
      border: 'none',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      padding: '8px',
      cursor: 'pointer',
    }
  };

  const menuItems = [
    { name: 'Episodes', route: '/all-episodes' },
    { name: 'Books', route: '/books' },
    { name: 'About', route: '/about-us' },
    { name: 'Donate', route: '/donate' },
  ];

  return (
    <nav style={styles.nav}>
      <div style={styles.container}>
        <Link to="/" style={styles.logo}>
          THE ENVIROHEALTH PODCAST
        </Link>
        
        {/* Mobile Menu Toggle */}
        <button 
          style={styles.menuToggle}
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <Menu size={24} />
        </button>

        {/* Desktop Menu */}
        <div style={styles.menuDesktop}>
          <div style={styles.linkContainer}>
            {menuItems.map(({ name, route }) => (
              <Link
                key={name}
                to={route}
                style={styles.link}
                onMouseEnter={(e) => (e.target.style.color = '#f97316')}
                onMouseLeave={(e) => (e.target.style.color = '#111827')}
              >
                {name}
              </Link>
            ))}
          </div>
          <div style={styles.actionsContainer}>
            <button
              style={styles.subscribeButton}
              onMouseEnter={e => e.target.style.backgroundColor = '#111827'}
              onMouseLeave={e => e.target.style.backgroundColor = 'black'}
            >
              Subscribe
            </button>
          </div>
        </div>

        {/* Full Screen Mobile Menu */}
        <div style={styles.menuMobile}>
          <div style={styles.mobileHeader}>
            <Link to="/" style={styles.logo}>
              THE ENVIROHEALTH PODCAST
            </Link>
            <button 
              style={styles.closeButton}
              onClick={() => setIsMenuOpen(false)}
            >
              <X size={24} />
            </button>
          </div>
          <div style={styles.mobileMenuItems}>
            {menuItems.map(({ name, route }) => (
              <Link
                key={name}
                to={route}
                style={styles.mobileMenuItem}
                onClick={() => setIsMenuOpen(false)}
              >
                {name}
                <ChevronRight size={24} />
              </Link>
            ))}
            <div style={{...styles.mobileMenuItem, border: 'none'}}>
              <button style={{...styles.subscribeButton, width: '100%'}}>
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;