import React, { useState } from 'react';
import { Linkedin, Twitter, Facebook, Youtube, Instagram } from 'lucide-react';

const Footer = () => {
  const [isDark, setIsDark] = useState(false);

  const styles = {
    container: {
      backgroundColor: 'white',
    },
    footer: {
      backgroundColor: isDark ? 'black' : '#f9fafb',
      color: isDark ? 'white' : '#111827',
    },
    footerContent: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: 'clamp(2rem, 5vw, 4rem) clamp(1rem, 3vw, 2rem)',
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: 'clamp(1.5rem, 3vw, 2rem)',
      marginBottom: 'clamp(2rem, 5vw, 4rem)',
    },
    heading: {
      fontSize: 'clamp(1rem, 1.25vw, 1.125rem)',
      fontWeight: 600,
      marginBottom: '1rem',
    },
    paragraph: {
      fontSize: '0.875rem',
      color: isDark ? '#9ca3af' : '#4b5563',
      lineHeight: '1.5',
      maxWidth: '300px',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: '0.75rem',
    },
    link: {
      fontSize: '0.875rem',
      color: isDark ? '#9ca3af' : '#4b5563',
      textDecoration: 'none',
      transition: 'color 0.2s',
    },
    iconContainer: {
      display: 'flex',
      gap: '1rem',
      flexWrap: 'wrap',
    },
    icon: {
      width: '20px',
      height: '20px',
      color: isDark ? '#9ca3af' : '#4b5563',
      cursor: 'pointer',
      transition: 'color 0.2s',
    },
    iconLink: {
      textDecoration: 'none',
    },
    spotifyIcon: {
      width: '20px',
      height: '20px',
    },
    bottomBar: {
      borderTop: `1px solid ${isDark ? '#1f2937' : '#e5e7eb'}`,
      paddingTop: 'clamp(1rem, 3vw, 2rem)',
      display: 'flex',
      flexDirection: ['column', 'row'],
      justifyContent: 'space-between',
      alignItems: ['flex-start', 'center'],
      gap: '1rem',
    },
    copyright: {
      fontSize: '0.875rem',
      color: isDark ? '#9ca3af' : '#4b5563',
      order: [2, 0],
    },
    bottomLinks: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: 'clamp(0.75rem, 2vw, 1.5rem)',
      fontSize: '0.875rem',
    },
  };

  const hoverStyles = {
    color: '#f97316',
  };


  const socialLinks = {
    twitter: "https://x.com/PodcastEnviro",
    instagram: "https://www.instagram.com/envirohealthpod/",
    spotify: "https://open.spotify.com/show/3fObeaoKI7wcnaaDEn4dNp?si=50946e17aa4a4003&nd=1&dlsi=078e73ba61384536",
    youtube: "https://www.youtube.com/@envirohealthpodcast2241",
    linkedin: "https://www.linkedin.com/company/envirohealth-podcast/?viewAsMember=true"
  };


  return (
    <div style={styles.container}>
      <footer style={styles.footer}>
        <div style={styles.footerContent}>
          <div style={styles.gridContainer}>
            <div>
              <h3 style={styles.heading}>About</h3>
              <p style={styles.paragraph}>
                Examining associations of the environment on human health through in-depth conversations with leading researchers.
              </p>
            </div>
            
            <div>
              <h3 style={styles.heading}>Quick Links</h3>
              <ul style={styles.list}>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Episodes</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>About Us</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Subscribe</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Contact</a></li>
              </ul>
            </div>

            <div>
              <h3 style={styles.heading}>Topics</h3>
              <ul style={styles.list}>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Research</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Technology</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Health</a></li>
                <li><a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Environment</a></li>
              </ul>
            </div>

            <div>
              <h3 style={styles.heading}>Connect</h3>
              <div style={styles.iconContainer}>
                <a 
                  href={socialLinks.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                >
                  <Linkedin 
                    style={styles.icon} 
                    onMouseEnter={e => e.target.style.color = hoverStyles.color} 
                    onMouseLeave={e => e.target.style.color = styles.icon.color}
                  />
                </a>
                <a 
                  href={socialLinks.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                >
                  <Twitter 
                    style={styles.icon} 
                    onMouseEnter={e => e.target.style.color = hoverStyles.color} 
                    onMouseLeave={e => e.target.style.color = styles.icon.color}
                  />
                </a>
                <a 
                  href={socialLinks.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                >
                  <Youtube 
                    style={styles.icon} 
                    onMouseEnter={e => e.target.style.color = hoverStyles.color} 
                    onMouseLeave={e => e.target.style.color = styles.icon.color}
                  />
                </a>
                <a 
                  href={socialLinks.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                >
                  <Instagram 
                    style={styles.icon} 
                    onMouseEnter={e => e.target.style.color = hoverStyles.color} 
                    onMouseLeave={e => e.target.style.color = styles.icon.color}
                  />
                </a>
                <a 
                  href={socialLinks.spotify}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.iconLink}
                >
                  <svg 
                    viewBox="0 0 24 24" 
                    style={styles.spotifyIcon}
                    fill="currentColor"
                    onMouseEnter={e => e.target.style.color = hoverStyles.color} 
                    onMouseLeave={e => e.target.style.color = styles.icon.color}
                  >
                    <path d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.66 0 12 0zm5.521 17.34c-.24.359-.66.48-1.021.24-2.82-1.74-6.36-2.101-10.561-1.141-.418.122-.779-.179-.899-.539-.12-.421.18-.78.54-.9 4.56-1.021 8.52-.6 11.64 1.32.42.18.479.659.301 1.02zm1.44-3.3c-.301.42-.841.6-1.262.3-3.239-1.98-8.159-2.58-11.939-1.38-.479.12-1.02-.12-1.14-.6-.12-.48.12-1.021.6-1.141C9.6 9.9 15 10.561 18.72 12.84c.361.181.54.78.241 1.2zm.12-3.36C15.24 8.4 8.82 8.16 5.16 9.301c-.6.179-1.2-.181-1.38-.721-.18-.601.18-1.2.72-1.381 4.26-1.26 11.28-1.02 15.721 1.621.539.3.719 1.02.419 1.56-.299.421-1.02.599-1.559.3z"/>
                  </svg>
                </a>
              </div>
            </div>
          </div>

          <div style={styles.bottomBar}>
            <div style={styles.copyright}>
              © 2025 ENVIROHEALTH. All rights reserved.
            </div>
            <div style={styles.bottomLinks}>
              <a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Privacy Policy</a>
              <a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Terms of Service</a>
              <a href="#" style={styles.link} onMouseEnter={e => e.target.style.color = hoverStyles.color} onMouseLeave={e => e.target.style.color = styles.link.color}>Cookie Policy</a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;