import React, { useState } from 'react';
import { ArrowLeft, ArrowRight, MessageSquare, Send, Mail } from 'lucide-react';

// Episode Navigation Component
const EpisodeNavigation = () => {
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '32px 0',
      borderTop: '1px solid #e5e7eb',
      borderBottom: '1px solid #e5e7eb',
      margin: '48px 0',
    },
    navLink: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      textDecoration: 'none',
      color: '#111827',
      transition: 'color 0.2s',
    },
    navText: {
      display: 'flex',
      flexDirection: 'column',
    },
    label: {
      fontSize: '0.875rem',
      color: '#6b7280',
    },
    title: {
      fontSize: '1rem',
      fontWeight: 500,
    },
  };

  return (
    <div style={styles.container}>
      <a 
        href="#" 
        style={styles.navLink}
        onMouseEnter={e => e.target.style.color = '#f97316'}
        onMouseLeave={e => e.target.style.color = '#111827'}
      >
        <ArrowLeft size={20} />
        <div style={styles.navText}>
          <span style={styles.label}>Previous Episode</span>
          <span style={styles.title}>Middle East Analysis Part 1</span>
        </div>
      </a>
      <a 
        href="#" 
        style={styles.navLink}
        onMouseEnter={e => e.target.style.color = '#f97316'}
        onMouseLeave={e => e.target.style.color = '#111827'}
      >
        <div style={styles.navText}>
          <span style={styles.label}>Next Episode</span>
          <span style={styles.title}>Regional Security Implications</span>
        </div>
        <ArrowRight size={20} />
      </a>
    </div>
  );
};

export default EpisodeNavigation;