import React from 'react';
import { BookOpen, Bookmark, ArrowRight } from 'lucide-react';

const ReadingRecommendations = () => {
  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '64px 24px',
      minHeight: '100vh',
    },
    header: {
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '16px',
    },
    filters: {
      display: 'flex',
      gap: '12px',
      justifyContent: 'center',
      flexWrap: 'wrap',
      marginBottom: '48px',
    },
    filterButton: {
      padding: '8px 16px',
      borderRadius: '20px',
      border: '1px solid #e5e7eb',
      backgroundColor: 'white',
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '32px',
    },
    bookCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '24px',
      border: '1px solid #f3f4f6',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
    },
    bookImage: {
      width: '100%',
      height: '200px',
      backgroundColor: '#f3f4f6',
      marginBottom: '24px',
      borderRadius: '4px',
      position: 'relative',
    },
    bookmark: {
      position: 'absolute',
      top: '12px',
      right: '12px',
      color: '#f97316',
      backgroundColor: 'rgba(249, 115, 22, 0.1)',
      padding: '6px',
      borderRadius: '4px',
    },
    bookTitle: {
      fontSize: '1.125rem',
      fontWeight: 600,
      marginBottom: '8px',
    },
    bookAuthor: {
      color: '#6b7280',
      fontSize: '0.875rem',
      marginBottom: '16px',
    },
    bookDescription: {
      color: '#4b5563',
      lineHeight: 1.6,
      marginBottom: '24px',
    },
    genreTag: {
      display: 'inline-block',
      padding: '4px 12px',
      backgroundColor: '#f3f4f6',
      borderRadius: '4px',
      fontSize: '0.75rem',
      color: '#4b5563',
    },
    featuredCard: {
      gridColumn: '1 / -1',
      backgroundColor: '#fff7ed',
      borderColor: '#f97316',
      display: 'flex',
      gap: '32px',
      alignItems: 'center',
      marginBottom: '32px',
    },
      container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '64px 24px',
        minHeight: '100vh',
      },
      header: {
        textAlign: 'center',
      },
      title: {
        fontSize: '2.5rem',
        fontWeight: 700,
        marginBottom: '16px',
      },
      filters: {
        display: 'flex',
        gap: '16px',
        justifyContent: 'center',
        marginBottom: '48px',
        flexWrap: 'wrap',
      },
      filterButton: {
        padding: '8px 16px',
        borderRadius: '20px',
        border: '1px solid #e5e7eb',
        backgroundColor: 'white',
        cursor: 'pointer',
        transition: 'all 0.2s ease',
      },
      episodeGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '32px',
        marginBottom: '64px',
      },
      episodeCard: {
        backgroundColor: 'white',
        borderRadius: '8px',
        overflow: 'hidden',
        transition: 'transform 0.2s ease, box-shadow 0.2s ease',
        border: '1px solid #f3f4f6',
      },
      episodeImage: {
        width: '100%',
        height: '200px',
        backgroundColor: '#f3f4f6',
        position: 'relative',
      },
      episodeContent: {
        padding: '24px',
      },
      episodeNumber: {
        color: '#6b7280',
        fontSize: '0.875rem',
        marginBottom: '8px',
        textTransform: 'uppercase',
        letterSpacing: '0.05em'
      },
      episodeTitle: {
        fontSize: '1.125rem',
        fontWeight: 600,
        marginBottom: '12px',
        lineHeight: 1.3,
      },
      episodeDate: {
        color: '#6b7280',
        fontSize: '0.875rem',
      },
      featuredCard: {
        gridColumn: '1 / -1',
        backgroundColor: '#fff7ed',
        borderColor: '#f97316',
        display: 'flex',
        gap: '32px',
        alignItems: 'center',
        marginBottom: '32px',
      },
      featuredButton: {
        backgroundColor: '#f97316',
        color: 'white',
        padding: '12px 24px',
        border: 'none',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        transition: 'background-color 0.2s',
      },
      featuredCard: {
        gridColumn: '1 / -1',
        backgroundColor: '#fff7ed',
        borderColor: '#f97316',
        display: 'flex',
        gap: '32px',
        alignItems: 'center',
        marginBottom: '32px',
        // Add responsive array values
        flexDirection: ['column', 'row'],
        padding: ['1.5rem', '2rem'],
        textAlign: ['center', 'left']
      },
  };

  const books = [
    {
      title: "Silent Spring",
      author: "Rachel Carson",
      description: "Landmark work exposing pesticide dangers and launching the modern environmental movement.",
      genre: "Environmental Science"
    },
    {
      title: "This Changes Everything: Capitalism vs. The Climate",
      author: "Naomi Klein",
      description: "Examines the relationship between economic systems and climate change.",
      genre: "Climate Policy"
    },
    {
      title: "The Sixth Extinction: An Unnatural History",
      author: "Elizabeth Kolbert",
      description: "Pulitzer-winning investigation into human-driven mass extinction events.",
      genre: "Biodiversity"
    },
    {
      title: "The Emperor of All Maladies: A Biography of Cancer",
      author: "Siddhartha Mukherjee",
      description: "Comprehensive history of cancer treatment and research.",
      genre: "Medical History"
    },
    {
      title: "Spillover: Animal Infections and the Next Human Pandemic",
      author: "David Quammen",
      description: "Explores zoonotic disease transmission and pandemic risks.",
      genre: "Epidemiology"
    },
    {
      title: "The Gene: An Intimate History",
      author: "Siddhartha Mukherjee",
      description: "Chronicles the science and ethics of genetic research.",
      genre: "Genetics"
    },
    {
      title: "The Uninhabitable Earth: Life After Warming",
      author: "David Wallace-Wells",
      description: "Urgent examination of climate change impacts.",
      genre: "Climate Change"
    },
    {
      title: "Doughnut Economics: Seven Ways to Think Like a 21st-Century Economist",
      author: "Kate Raworth",
      description: "Proposes sustainable economic models respecting planetary boundaries.",
      genre: "Sustainable Economics"
    },
    {
      title: "The Death and Life of Great American Cities",
      author: "Jane Jacobs",
      description: "Seminal work on urban planning and community health.",
      genre: "Urban Health & Policy"
    },
    {
      title: "Waste: One Woman's Fight Against America's Dirty Secret",
      author: "Catherine Coleman Flowers",
      description: "Exposes America's sanitation crisis and public health disparities.",
      genre: "Environmental Justice"
    }
];

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>Essential Reading List</h1>
        <p style={{ ...styles.bookAuthor, textAlign: 'center' }}>
          Curated book series to deepen your understanding of the environment and health
        </p>
      </header>

      <div style={styles.filters}>
        {['All', 'Geopolitics', 'History', 'Middle East', 'Foreign Policy'].map((filter) => (
          <button
            key={filter}
            style={styles.filterButton}
            onMouseEnter={e => {
              e.target.style.backgroundColor = '#f9fafb';
              e.target.style.borderColor = '#f3f4f6';
            }}
            onMouseLeave={e => {
              e.target.style.backgroundColor = 'white';
              e.target.style.borderColor = '#e5e7eb';
            }}
          >
            {filter}
          </button>
        ))}
      </div>

      <div style={styles.grid}>
        <div style={{ 
          ...styles.bookCard, 
          ...styles.featuredCard,
          flexDirection: ['column', 'row'], // Stack vertically on mobile
          gap: 'clamp(1.5rem, 3vw, 2rem)', // Responsive gap
          padding: 'clamp(1.5rem, 3vw, 2rem)', // Responsive padding
        }}>
          <div style={{ 
            ...styles.bookImage, 
            flex: '0 0 clamp(150px, 30vw, 200px)', // Responsive image size
            height: 'auto', // Maintain aspect ratio
            aspectRatio: '3/4', // Better book aspect ratio
          }}></div>
          <div style={{ flex: 1 }}>
            <div style={styles.genreTag}>Featured Recommendation</div>
            <h2 style={{ 
              ...styles.bookTitle, 
              fontSize: 'clamp(1.25rem, 2.5vw, 1.5rem)', // Fluid typography
              marginBottom: '0.75rem'
            }}>
              "The Future We Choose"
            </h2>
            <p style={{ 
              ...styles.bookAuthor, 
              fontSize: 'clamp(0.875rem, 1.25vw, 1rem)', // Fluid typography
              marginBottom: '1rem'
            }}>
              by Christiana Figueres & Tom Rivett-Carnac
            </p>
            <p style={{
              ...styles.bookDescription,
              fontSize: 'clamp(0.875rem, 1.25vw, 1rem)', // Fluid typography
              marginBottom: 'clamp(1rem, 2vw, 1.5rem)'
            }}>
              A compelling guide on how we can tackle climate change, improve global health, 
              and build a sustainable future through collective action and innovative solutions.
            </p>
            <button
              style={{
                backgroundColor: '#f97316',
                color: 'white',
                padding: 'clamp(0.75rem, 1.5vw, 1rem) clamp(1rem, 2vw, 1.5rem)',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: '0.5rem',
                transition: 'background-color 0.2s',
                width: ['100%', 'auto'], // Full width on mobile
                justifyContent: 'center',
              }}
              onMouseEnter={e => e.target.style.backgroundColor = '#ea580c'}
              onMouseLeave={e => e.target.style.backgroundColor = '#f97316'}
            >
              View Book <ArrowRight size={18} />
            </button>
          </div>
        </div>

        {books.map((book, index) => (
        <div 
          key={index}
          style={styles.episodeCard}
          onMouseEnter={e => {
            e.currentTarget.style.transform = 'translateY(-4px)';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(0, 0, 0, 0.08)';
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'none';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          <div style={styles.episodeImage}>
            <Bookmark 
              size={20} 
              style={{
                position: 'absolute',
                top: '16px',
                right: '16px',
                color: '#f97316',
                backgroundColor: 'rgba(249, 115, 22, 0.1)',
                padding: '6px',
                borderRadius: '4px',
                transition: 'transform 0.2s ease',
              }}
              onMouseEnter={e => e.currentTarget.style.transform = 'scale(1.1)'}
              onMouseLeave={e => e.currentTarget.style.transform = 'scale(1)'}
            />
          </div>
          <div style={styles.episodeContent}>
            <div style={{
              ...styles.episodeNumber,
              textTransform: 'uppercase',
              letterSpacing: '0.05em'
            }}>
              {book.genre}
            </div>
            <h3 style={styles.episodeTitle}>{book.title}</h3>
            <div style={{ ...styles.episodeDate, marginBottom: '8px' }}>
              by {book.author}
            </div>
            <p style={{
              color: '#4b5563',
              fontSize: '0.875rem',
              lineHeight: 1.5
            }}>
              {book.description}
            </p>
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default ReadingRecommendations;