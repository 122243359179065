import React from 'react';

const EpisodeSegments = ({ segments }) => {
  const styles = {
    container: {
      margin: '32px 0',
      padding: '24px 0',
    },
    segmentItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '16px 24px',
      margin: '8px 0',
      borderRadius: '8px',
      transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
      cursor: 'pointer',
      backgroundColor: '#ffffff',
      border: '1px solid #f3f4f6',
    },
    timeBadge: {
      fontSize: '0.75rem',
      fontWeight: 500,
      color: '#f97316',
      backgroundColor: '#fff7ed',
      padding: '4px 8px',
      borderRadius: '4px',
      marginRight: '16px',
      minWidth: '64px',
      textAlign: 'center',
    },
    title: {
      fontSize: '0.95rem',
      fontWeight: 500,
      color: '#111827',
      lineHeight: 1.5,
    },
    hoverEffect: {
      transform: 'translateX(4px)',
      borderColor: '#fde68a',
      boxShadow: '0 2px 6px rgba(0, 0, 0, 0.05)',
    }
  };

  return (
    <div style={styles.container}>
      <h3 style={{ 
        fontSize: '1.25rem',
        fontWeight: 600,
        color: '#111827',
        marginBottom: '24px'
      }}>
        Episode Segments
      </h3>
      
      {segments.map((segment, index) => (
        <div
          key={index}
          style={styles.segmentItem}
          onMouseEnter={e => {
            e.currentTarget.style.transform = styles.hoverEffect.transform;
            e.currentTarget.style.borderColor = styles.hoverEffect.borderColor;
            e.currentTarget.style.boxShadow = styles.hoverEffect.boxShadow;
          }}
          onMouseLeave={e => {
            e.currentTarget.style.transform = 'none';
            e.currentTarget.style.borderColor = '#f3f4f6';
            e.currentTarget.style.boxShadow = 'none';
          }}
        >
          <span style={styles.timeBadge}>{segment.time}</span>
          <span style={styles.title}>{segment.title}</span>
        </div>
      ))}
    </div>
  );
};

export default EpisodeSegments;