import React, { useState } from 'react';
import { ArrowRight, Hash } from 'lucide-react';
import NewsletterSignup from '../../components/NewsletterSignUp/NewsletterSignUp';

const LandingPage = () => {
  const styles = {
    container: {
      minHeight: '100vh',
      backgroundColor: 'white',
    },
    heroSection: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: 'clamp(4rem, 8vw, 8rem) 1.5rem',
    },
    mainTitle: {
      fontSize: 'clamp(2.5rem, 6vw, 4rem)',
      fontWeight: 500,
      lineHeight: '1.2',
      letterSpacing: '-0.02em',
      marginBottom: '1.5rem',
      color: '#111827',
    },
    subtitle: {
      fontSize: 'clamp(1.25rem, 2.5vw, 1.5rem)',
      color: '#4b5563',
      maxWidth: '800px',
      marginBottom: 'clamp(4rem, 6vw, 6rem)',
      lineHeight: '1.5',
      fontWeight: 400,
    },
    latestSection: {
      marginTop: 'clamp(4rem, 8vw, 8rem)',
    },
    sectionLabel: {
      fontSize: 'clamp(0.875rem, 1vw, 1rem)',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
      color: '#6b7280',
      marginBottom: '2rem',
      fontWeight: 500,
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: 'clamp(2rem, 4vw, 4rem)',
      alignItems: 'start',
    },
    featuredImage: {
      aspectRatio: '16/9',
      backgroundColor: '#f3f4f6',
      marginBottom: '2rem',
      borderRadius: '0.5rem',
    },
    episodeTitle: {
      fontSize: 'clamp(1.5rem, 2.5vw, 2rem)',
      fontWeight: 500,
      marginBottom: '1rem',
      color: '#111827',
      lineHeight: '1.3',
    },
    episodeDescription: {
      color: '#4b5563',
      marginBottom: '2rem',
      fontSize: 'clamp(1rem, 1.5vw, 1.125rem)',
      lineHeight: '1.7',
    },
    listenButton: {
      backgroundColor: '#111827',
      color: 'white',
      padding: '1rem 2rem',
      border: 'none',
      borderRadius: '0.25rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      transition: 'all 0.2s',
      fontSize: '1rem',
      fontWeight: 500,
    },
    recentTitle: {
      fontSize: 'clamp(1.25rem, 2vw, 1.5rem)',
      fontWeight: 500,
      marginBottom: '2rem',
      color: '#111827',
    },
    recentEpisode: {
      marginBottom: '2.5rem',
      cursor: 'pointer',
      display: 'flex',
      gap: '1.5rem',
      alignItems: 'flex-start',
    },
    thumbnail: {
      width: '8rem',
      minWidth: '8rem',
      height: '5rem',
      backgroundColor: '#f3f4f6',
      borderRadius: '0.375rem',
    },
    episodeNumber: {
      fontSize: 'clamp(1rem, 1.25vw, 1.125rem)',
      fontWeight: 500,
      marginBottom: '0.5rem',
      color: '#111827',
      transition: 'color 0.2s',
    },
    date: {
      fontSize: '0.875rem',
      color: '#6b7280',
    },
    topicsSection: {
      padding: 'clamp(4rem, 8vw, 8rem) 1.5rem',
    },
    topicsSectionContent: {
      maxWidth: '1200px',
      margin: '0 auto',
    },
    topicsHeader: {
      marginBottom: 'clamp(3rem, 5vw, 4rem)',
    },
    topicsTitle: {
      fontSize: 'clamp(1.25rem, 2.5vw, 1.5rem)',
      fontWeight: 500,
      color: '#111827',
      marginBottom: '1rem',
    },
    topicsDescription: {
      fontSize: 'clamp(1rem, 1.5vw, 1.125rem)',
      color: '#6b7280',
      maxWidth: '600px',
      lineHeight: '1.6',
    },
    topicsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '2rem',
    },
    topicCard: {
      border: '1px solid #e5e7eb',
      padding: '2rem',
      borderRadius: '0.5rem',
      cursor: 'pointer',
      transition: 'all 0.2s',
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
    },
    topicIcon: {
      width: '2.5rem',
      height: '2.5rem',
      backgroundColor: '#f3f4f6',
      borderRadius: '0.375rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#6b7280',
    },
    topicTitle: {
      fontSize: '1.25rem',
      fontWeight: 500,
      color: '#111827',
      marginBottom: '0.5rem',
    },
    topicDescription: {
      fontSize: '0.875rem',
      color: '#6b7280',
      lineHeight: '1.6',
      marginBottom: '1rem',
    },
    topicMeta: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.75rem',
      marginTop: 'auto',
    },
    topicCount: {
      fontSize: '0.875rem',
      color: '#6b7280',
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    topicArrow: {
      marginLeft: 'auto',
      opacity: 0,
      transition: 'all 0.2s',
    }
  };

  const [hoveredEpisode, setHoveredEpisode] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [hoveredTopic, setHoveredTopic] = useState(null);

  const topicsData = [
    {
      title: 'Research Methods',
      description: 'Deep dives into the methodologies behind environmental health studies',
      count: 28
    },
    {
      title: 'Climate & Health',
      description: 'Exploring the health impacts of climate change and extreme weather',
      count: 35
    },
    {
      title: 'Urban Health',
      description: 'How city design and infrastructure affect public health outcomes',
      count: 42
    },
    {
      title: 'Policy Impact',
      description: 'Analysis of environmental policies and their health implications',
      count: 23
    },
    {
      title: 'Water Systems',
      description: 'Research on water quality, access, and public health',
      count: 31
    },
    {
      title: 'Air Quality',
      description: 'Studies on air pollution and respiratory health',
      count: 39
    }
  ];

  return (
    <div style={styles.container}>
    <div style={styles.heroSection}>
      <h1 style={styles.mainTitle}>
        THE ENVIROHEALTH PODCAST
      </h1>
      <p style={styles.subtitle}>
        Examining associations of the environment on human health. From air quality to water systems, climate patterns to urban design - our environment shapes our health in countless ways.
      </p>
      
      <div style={styles.latestSection}>
        <h2 style={styles.sectionLabel}>Latest Episode</h2>
        <div style={styles.gridContainer}>
          <div>
            <div style={styles.featuredImage}></div>
            <h2 style={styles.episodeTitle}>Climate Change and Respiratory Health: New Research Findings</h2>
            <p style={styles.episodeDescription}>
              Join us as we explore groundbreaking research on the relationship between changing climate patterns and respiratory health outcomes, featuring insights from leading environmental health scientists.
            </p>
            <button
              style={styles.listenButton}
              onMouseEnter={e => e.target.style.backgroundColor = '#1f2937'}
              onMouseLeave={e => e.target.style.backgroundColor = '#111827'}
            >
              Listen Now <ArrowRight size={20} />
            </button>
          </div>
          
          <div>
            <h3 style={styles.recentTitle}>Recent Episodes</h3>
            {[1, 2, 3].map(i => (
              <div
                key={i}
                style={styles.recentEpisode}
                onMouseEnter={() => setHoveredEpisode(i)}
                onMouseLeave={() => setHoveredEpisode(null)}
              >
                <div style={styles.thumbnail}></div>
                <div>
                  <h4 style={{
                    ...styles.episodeNumber,
                    color: hoveredEpisode === i ? '#4b5563' : '#111827'
                  }}>
                    Urban Air Quality and Child Development
                  </h4>
                  <p style={styles.date}>January {20 - i}, 2025</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <NewsletterSignup />

    <div style={styles.topicsSection}>
        <div style={styles.topicsSectionContent}>
          <div style={styles.topicsHeader}>
            <h2 style={styles.topicsTitle}>Browse Episodes by Theme</h2>
            <p style={styles.topicsDescription}>
              Explore our episodes organised by research areas and trending topics in environmental health science
            </p>
          </div>
          
          <div style={styles.topicsGrid}>
            {topicsData.map(topic => (
              <div
                key={topic.title}
                style={{
                  ...styles.topicCard,
                  transform: hoveredTopic === topic.title ? 'translateY(-2px)' : 'none',
                  boxShadow: hoveredTopic === topic.title ? '0 4px 12px -2px rgb(0 0 0 / 0.1)' : 'none'
                }}
                onMouseEnter={() => setHoveredTopic(topic.title)}
                onMouseLeave={() => setHoveredTopic(null)}
              >
                <div style={styles.topicIcon}>
                  <Hash size={20} />
                </div>
                <div>
                  <h3 style={styles.topicTitle}>{topic.title}</h3>
                  <p style={styles.topicDescription}>{topic.description}</p>
                </div>
                <div style={styles.topicMeta}>
                  <span style={styles.topicCount}>
                    {topic.count} episodes
                  </span>
                  <ArrowRight 
                    size={16} 
                    style={{
                      ...styles.topicArrow,
                      opacity: hoveredTopic === topic.title ? 1 : 0,
                      transform: hoveredTopic === topic.title ? 'translateX(4px)' : 'none'
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
  
  </div>
);
};

export default LandingPage;