import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

// pages
import LandingPage from "./pages/LandingPage/LandingPage";
import EpisodePage from "./pages/EpisodePage/EpisodePage";
import AllEpisodesPage from "./pages/AllEpisodes/AllEpisodes";
import AboutUs from "./pages/AboutUs/AboutUs";
import ReadingRecommendations from "./pages/RecommendedReading/RecommendedReading";
import DonatePage from "./pages/Donate/Donate";
const App = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow p-4">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/about" element={<EpisodePage />} />
            <Route path="/all-episodes" element={<AllEpisodesPage />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/books" element={<ReadingRecommendations />} />
            <Route path="/donate" element={<DonatePage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;