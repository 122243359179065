import React, { useState, useRef, useEffect } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const Transcript = ({ transcriptContent }) => {
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [transcriptContent]);

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const styles = {
    transcriptContainer: {
      marginTop: '32px',
      padding: '24px 0',
    },
    toggleButton: {
      width: '100%',
      padding: '16px 24px',
      backgroundColor: '#ffffff',
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
      marginBottom: '16px',
      ':hover': {
        backgroundColor: '#f9fafb',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        transform: 'translateY(-1px)'
      }
    },
    toggleText: {
      fontWeight: 600,
      color: '#111827',
      fontSize: '1rem',
      letterSpacing: '-0.01em'
    },
    icon: {
      color: '#f97316',
      marginLeft: '12px'
    },
    contentWrapper: {
      maxHeight: isTranscriptOpen ? '70vh' : '0',
      overflowY: 'auto',
      transition: 'max-height 0.4s cubic-bezier(0.4, 0, 0.2, 1)',
      borderRadius: '8px',
      border: isTranscriptOpen ? '1px solid #e5e7eb' : 'none',
      padding: isTranscriptOpen ? '16px' : '0'
    },
    sectionHeader: {
      backgroundColor: '#f8fafc',
      padding: '16px 20px',
      borderRadius: '6px',
      margin: '24px 0 16px',
      fontWeight: 600,
      color: '#111827',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
    },
    speakerEntry: {
      margin: '16px 0',
      paddingLeft: '32px',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '16px',
        top: '8px',
        width: '4px',
        height: '4px',
        backgroundColor: '#e5e7eb',
        borderRadius: '50%'
      }
    },
    speakerName: {
      fontWeight: 500,
      color: '#f97316',
      marginBottom: '4px',
      fontSize: '0.95rem'
    },
    timeStamp: {
      fontSize: '0.75rem',
      color: '#6b7280',
      marginLeft: '8px',
      fontWeight: 500
    },
    speakerText: {
      color: '#374151',
      lineHeight: 1.7,
      fontSize: '0.95rem',
      marginBottom: '8px'
    },
    noteText: {
      fontSize: '0.875rem',
      color: '#6b7280',
      fontStyle: 'italic',
      margin: '12px 0',
      paddingLeft: '24px',
      position: 'relative',
      '&:before': {
        content: '"ⓘ"',
        position: 'absolute',
        left: '0',
        top: '0',
        color: '#9ca3af'
      }
    }
  };

  return (
    <div style={styles.transcriptContainer}>
      <button
        style={styles.toggleButton}
        onClick={() => setIsTranscriptOpen(!isTranscriptOpen)}
        onMouseEnter={e => e.target.style.backgroundColor = '#f9fafb'}
        onMouseLeave={e => e.target.style.backgroundColor = '#ffffff'}
      >
        <span style={styles.toggleText}>
          {isTranscriptOpen ? 'Hide Full Transcript' : 'Show Full Transcript'}
        </span>
        {isTranscriptOpen ? (
          <ChevronUp size={20} style={styles.icon} />
        ) : (
          <ChevronDown size={20} style={styles.icon} />
        )}
      </button>

      <div 
        ref={contentRef} 
        style={styles.contentWrapper}
      >
        {transcriptContent.sections.map((section, index) => (
          <div key={index}>
            <div style={styles.sectionHeader}>
              <span>{section.sectionHeader}</span>
              <span style={styles.timeStamp}>
                {formatTime(section.startTime)} – {formatTime(section.endTime)}
              </span>
            </div>

            {section.entries.map((entry, entryIndex) => (
              <div key={entryIndex} style={entry.type === 'note' ? styles.noteText : styles.speakerEntry}>
                {entry.type === 'speaker' ? (
                  <>
                    <div style={styles.speakerName}>
                      {entry.speaker}
                      <span style={styles.timeStamp}>
                        [{formatTime(entry.startTime)}]
                      </span>
                    </div>
                    <div style={styles.speakerText}>{entry.text}</div>
                  </>
                ) : (
                  <div style={styles.noteText}>{entry.text}</div>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Transcript;