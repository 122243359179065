import React from 'react';
import { Mic, Podcast, Mail, Twitter, Linkedin, Waves, Clock, Globe, Users, Microscope, Award, BookOpen } from 'lucide-react';
import MetricsSection from './Components/Metrics/Metrics';
import ResearchTimeline from './Components/Timeline/Timeline';
import NewsletterSignup from '../../components/NewsletterSignUp/NewsletterSignUp';
const AboutUs = () => {
  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '64px 24px',
      minHeight: '100vh',
    },
    header: {
      textAlign: 'center',
      marginBottom: '64px',
      position: 'relative',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '16px',
      position: 'relative',
      zIndex: 1,
    },
    subtitle: {
      fontSize: '1.125rem',
      color: '#4b5563',
      maxWidth: '600px',
      margin: '0 auto',
      position: 'relative',
      zIndex: 1,
    },
    waveform: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      opacity: 0.05,
      color: '#f97316',
      width: '100%',
      height: 'auto',
    },
    teamGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '48px',
      margin: '64px 0',
      position: 'relative',
    },
    memberCard: {
      backgroundColor: 'white',
      padding: '32px',
      borderRadius: '16px',
      border: '1px solid #f3f4f6',
      transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
      textAlign: 'center',
      position: 'relative',
      overflow: 'hidden',
    },
    avatar: {
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      backgroundColor: '#f3f4f6',
      margin: '0 auto 24px',
      position: 'relative',
      overflow: 'hidden',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
    },
    avatarPattern: {
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'linear-gradient(45deg, #f97316 0%, #fdba74 100%)',
      opacity: 0.1,
    },
    name: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '8px',
      position: 'relative',
    },
    role: {
      color: '#f97316',
      fontSize: '0.875rem',
      fontWeight: 500,
      marginBottom: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
    },
    roleIcon: {
      width: '16px',
      height: '16px',
    },
    bio: {
      color: '#4b5563',
      lineHeight: 1.6,
      marginBottom: '24px',
      position: 'relative',
    },
    socialLinks: {
      display: 'flex',
      justifyContent: 'center',
      gap: '16px',
      position: 'relative',
    },
    icon: {
      color: '#6b7280',
      transition: 'all 0.3s ease',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
      padding: '8px',
    },
    stats: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
      gap: '32px',
      margin: '64px 0',
      textAlign: 'center',
    },
    statItem: {
      padding: '32px',
      border: '1px solid #f3f4f6',
      borderRadius: '16px',
      transition: 'all 0.3s ease',
    },
    statNumber: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#f97316',
      marginBottom: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
    },
    statLabel: {
      color: '#4b5563',
      fontSize: '0.875rem',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
    },
    timeline: {
      margin: '64px 0',
      padding: '32px',
      borderLeft: '2px solid #f3f4f6',
      position: 'relative',
    },
    timelineItem: {
      marginBottom: '48px',
      paddingLeft: '32px',
      position: 'relative',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: '-8px',
        top: '4px',
        width: '16px',
        height: '16px',
        backgroundColor: '#f97316',
        borderRadius: '50%',
      }
    },
    bookAuthor: {
      color: '#6b7280',
      fontSize: '0.875rem',
      marginBottom: '16px',
    },
    milestoneIcon: {
      position: 'absolute',
      left: '-41px',
      top: '0',
      width: '24px',
      height: '24px',
      backgroundColor: '#fff',
      border: '2px solid #f97316',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#f97316',
    },
    milestoneContent: {
      backgroundColor: '#fff',
      border: '1px solid #f3f4f6',
      borderRadius: '8px',
      padding: '24px',
      marginBottom: '32px',
    },
    milestoneTitle: {
      fontSize: '1.125rem',
      fontWeight: '600',
      marginBottom: '8px',
      color: '#111827',
    },
    milestoneDescription: {
      color: '#4b5563',
      lineHeight: '1.6',
    },
    // Add new styles for improved sections
    episodeHighlight: {
      marginTop: '48px',
      marginBottom: '64px',
    },
    episodeGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '24px',
      marginTop: '32px',
    },
    episodeCard: {
      backgroundColor: '#fff',
      border: '1px solid #f3f4f6',
      borderRadius: '12px',
      padding: '24px',
      transition: 'all 0.3s ease',
    },
    episodeHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      marginBottom: '16px',
    },
    episodeIcon: {
      backgroundColor: '#fff7ed',
      color: '#f97316',
      padding: '8px',
      borderRadius: '8px',
    },
    socialButton: {
      cursor: 'pointer',
      transition: 'all 0.2s ease',
    },
  };

  const featuredEpisodes = [
    {
      title: "Air Quality & Public Health",
      description: "Investigating the relationship between urban air pollution and respiratory health outcomes",
      icon: <Microscope />,
      date: "Feb 2024",
      impact: "Referenced in WHO report"
    },
    {
      title: "Chemical Exposure Analysis",
      description: "Deep dive into modern analytical methods for detecting environmental toxins",
      icon: <BookOpen />,
      date: "Jan 2024",
      impact: "Featured in Nature"
    },
    {
      title: "Environmental Justice",
      description: "Examining disparities in environmental exposure across communities",
      icon: <Users />,
      date: "Dec 2023",
      impact: "Policy impact study"
    }
  ];

  const milestones = [
    {
      year: '2020',
      title: 'Foundation & First Studies',
      description: 'Launched our first environmental health analysis series, establishing collaboration with leading research institutions.',
      icon: <Microscope size={16} />
    },
    {
      year: '2021',
      title: 'Growing Impact',
      description: 'Reached 100k monthly listeners. Our research was cited in major environmental policy discussions.',
      icon: <Users size={16} />
    },
    {
      year: '2023',
      title: 'International Recognition',
      description: 'Featured in Apple Podcasts Top Shows. Expanded coverage to global environmental health challenges.',
      icon: <Award size={16} />
    },
    {
      year: '2024',
      title: 'Research Innovation',
      description: 'Pioneered new methodologies in environmental exposure analysis. Launched collaborative research projects.',
      icon: <BookOpen size={16} />
    }
  ];

  const handleSocialClick = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div style={styles.container}>
      {/* Header section remains the same */}
      <header style={styles.header}>
        <h1 style={styles.title}>The Voices Behind the Analysis</h1>
        <p style={styles.bookAuthor}>
          Equal partners in curiosity - combining decade-spanning expertise 
          to decode associations of the environment on human health
        </p>
      </header>

      {/* Team section with updated social links */}
      <div style={styles.teamGrid}>
        {[{
          name: "Dr. Joseph Levermore",
          role: "Optical Physicist & AI Engineer",
          bio: "• Optical Physicist & AI Engineer  • Expertise in Analytical Toxicology • Chemical exposure analysis",
          pattern: "pattern1",
          linkedIn: 'https://www.linkedin.com/in/josephlevermore/',
          bluesky: 'https://bsky.app/profile/josephlevermore.bsky.social',
          email: 'joseph.levermore@eden-microfluidics.com'
        }, {
          name: "Dr. Steven J. Campbell",
          role: "Marie Skłodowska Curie Research Fellow",
          bio: "• Environmental researcher • Investigating toxic pollutants • Data-driven air quality insights",
          pattern: "pattern2",
          linkedIn: 'https://www.linkedin.com/in/steven-j-campbell-997075108/',
          bluesky: 'https://bsky.app/profile/stevenjcampbell.bsky.social',
          email: 'steven.campbell@imperial.ac.uk'
        }].map((member, index) => (
          <div 
            key={index}
            style={styles.memberCard}
            onMouseEnter={e => {
              e.currentTarget.style.transform = 'translateY(-8px)';
              e.currentTarget.style.boxShadow = '0 12px 24px rgba(0, 0, 0, 0.05)';
            }}
            onMouseLeave={e => {
              e.currentTarget.style.transform = 'none';
              e.currentTarget.style.boxShadow = 'none';
            }}
          >
            <div style={styles.avatar}>
              <div style={{
                ...styles.avatarPattern,
                background: `linear-gradient(${index === 0 ? '45deg' : '-45deg'}, #f97316 0%, #fdba74 100%)`
              }} />
            </div>
            <h3 style={styles.name}>{member.name}</h3>
            <div style={styles.role}>
              <Mic size={16} style={styles.roleIcon} />
              {member.role}
            </div>
            <p style={styles.bio}>{member.bio}</p>
            <div style={styles.socialLinks}>
              <div 
                style={styles.socialButton}
                onClick={() => handleSocialClick(member.bluesky)}
                onMouseEnter={e => {
                  e.currentTarget.style.color = '#1DA1F2';
                  e.currentTarget.style.backgroundColor = '#E8F5FE';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.color = '#6b7280';
                  e.currentTarget.style.backgroundColor = '#f9fafb';
                }}
              >
                <Twitter size={20} style={styles.icon} />
              </div>
              <div 
                style={styles.socialButton}
                onClick={() => handleSocialClick(member.linkedIn)}
                onMouseEnter={e => {
                  e.currentTarget.style.color = '#0A66C2';
                  e.currentTarget.style.backgroundColor = '#E8F0FA';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.color = '#6b7280';
                  e.currentTarget.style.backgroundColor = '#f9fafb';
                }}
              >
                <Linkedin size={20} style={styles.icon} />
              </div>
              <div 
                style={styles.socialButton}
                onClick={() => handleSocialClick(`mailto:${member.email}`)}
                onMouseEnter={e => {
                  e.currentTarget.style.color = '#f97316';
                  e.currentTarget.style.backgroundColor = '#FFEDD5';
                }}
                onMouseLeave={e => {
                  e.currentTarget.style.color = '#6b7280';
                  e.currentTarget.style.backgroundColor = '#f9fafb';
                }}
              >
                <Mail size={20} style={styles.icon} />
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Featured Episodes Section */}
      <div style={styles.episodeHighlight}>
      <h2 style={{        fontSize: '1.75rem',
        fontWeight: 700,
        marginBottom: '24px',
        textAlign: 'center'
        }}>
          Podcast Impact
        </h2>
        <div style={styles.episodeGrid}>
          {featuredEpisodes.map((episode, index) => (
            <div
              key={index}
              style={styles.episodeCard}
              onMouseEnter={e => {
                e.currentTarget.style.transform = 'translateY(-4px)';
                e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.05)';
              }}
              onMouseLeave={e => {
                e.currentTarget.style.transform = 'none';
                e.currentTarget.style.boxShadow = 'none';
              }}
            >
              <div style={styles.episodeHeader}>
                <div style={styles.episodeIcon}>
                  {episode.icon}
                </div>
                <div>
                  <div style={{fontWeight: '500'}}>{episode.date}</div>
                  <div style={{fontSize: '0.875rem', color: '#f97316'}}>{episode.impact}</div>
                </div>
              </div>
              <h3 style={{fontSize: '1.125rem', fontWeight: '600', marginBottom: '8px'}}>
                {episode.title}
              </h3>
              <p style={{color: '#4b5563', fontSize: '0.875rem'}}>
                {episode.description}
              </p>
            </div>
          ))}
        </div>
      </div>

      {/* Updated Stats Section */}
      <MetricsSection />

      <ResearchTimeline />
      <NewsletterSignup />
    </div>
  );
};

export default AboutUs;