import React, { useState } from 'react';


const GuestBio = () => {
  const styles = {
    container: {
      marginTop: '48px',
    },
    header: {
      display: 'flex',
      gap: '24px',
      marginBottom: '24px',
    },
    imageContainer: {
      width: '120px',
      height: '120px',
      borderRadius: '50%',
      backgroundColor: '#f3f4f6',
      flexShrink: 0,
    },
    info: {
      flex: 1,
    },
    name: {
      fontSize: '1.5rem',
      fontWeight: 600,
      marginBottom: '8px',
    },
    title: {
      color: '#6b7280',
      marginBottom: '16px',
    },
    bio: {
      color: '#374151',
      lineHeight: 1.7,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.imageContainer}></div>
        <div style={styles.info}>
          <h3 style={styles.name}>Dr. Dan Schueftan</h3>
          <div style={styles.title}>Director of the National Security Studies Center</div>
        </div>
      </div>
      <p style={styles.bio}>
        Dr. Dan Schueftan is the Director of the National Security Studies Center at the University of Haifa, 
        and a senior lecturer at the School of Political Sciences. With over three decades of experience in 
        Middle Eastern affairs, he has served as an advisor to Israel's National Security Council and former 
        Prime Ministers.
      </p>
    </div>
  );
};

export default GuestBio;