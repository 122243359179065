import { useState } from "react";
import { Mail } from 'lucide-react';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const styles = {
    container: {
      padding: 'clamp(3rem, 6vw, 6rem) 1.5rem',
      backgroundColor: 'white',
      color: '#111827',
    },
    innerContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      display: 'flex',
      flexDirection: 'column',
      gap: 'clamp(2rem, 4vw, 3rem)',
      '@media (min-width: 768px)': {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      },
    },
    content: {
      flex: '1',
      maxWidth: '600px',
    },
    title: {
      fontSize: 'clamp(1.5rem, 3vw, 2rem)',
      fontWeight: 500,
      marginBottom: 'clamp(0.75rem, 1.5vw, 1rem)',
      lineHeight: '1.3',
    },
    description: {
      fontSize: 'clamp(1rem, 1.5vw, 1.125rem)',
      color: '#4b5563',
      lineHeight: '1.6',
    },
    formContainer: {
      flex: '1',
      maxWidth: '500px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      '@media (min-width: 640px)': {
        flexDirection: 'row',
      },
    },
    inputWrapper: {
      flex: '1',
      position: 'relative',
    },
    input: {
      width: '100%',
      padding: '1rem 1.25rem',
      fontSize: '1rem',
      border: '1px solid #e5e7eb',
      borderRadius: '0.375rem',
      transition: 'all 0.2s',
      backgroundColor: '#f9fafb',
      color: '#111827',
      '&:focus': {
        outline: 'none',
        borderColor: '#d1d5db',
        backgroundColor: 'white',
      },
    },
    button: {
      backgroundColor: '#111827',
      color: 'white',
      padding: '1rem 1.5rem',
      border: 'none',
      borderRadius: '0.375rem',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.75rem',
      transition: 'all 0.2s',
      fontSize: '1rem',
      fontWeight: 500,
      whiteSpace: 'nowrap',
      minWidth: 'fit-content',
    },
  };

  return (
    <section style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.content}>
          <h2 style={styles.title}>Stay Updated with Environmental Health Science</h2>
          <p style={styles.description}>
            Subscribe to our newsletter for the latest research findings, podcast episodes, and insights in environmental health.
          </p>
        </div>
        
        <div style={styles.formContainer}>
          <form 
            style={styles.form} 
            onSubmit={(e) => {
              e.preventDefault();
              // Handle form submission
            }}
          >
            <div style={styles.inputWrapper}>
              <input
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{
                  ...styles.input,
                  borderColor: email ? '#9ca3af' : '#e5e7eb',
                }}
                required
              />
            </div>
            
            <button
              type="submit"
              style={{
                ...styles.button,
                backgroundColor: isHovered ? '#1f2937' : '#111827',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <Mail size={18} />
              Subscribe Now
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default NewsletterSignup;