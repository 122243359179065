import React, { useState } from 'react';
import { Play, Youtube, ExternalLink } from 'lucide-react';
import Comments from './Components/CommentSection/CommentSection';
import EpisodeNavigation from './Components/EpisodeNavigation/EpisodeNavigation';
import EpisodeNotes from './Components/EpisodeNotes/EpisodeNotes';
import GuestBio from './Components/GuestSpeakerInformation/GuestSpeakerInformation';
import NewsletterSignup from '../../components/NewsletterSignUp/NewsletterSignUp';
import EpisodeSegments from './Components/EpisodeSegments/EpisodeSegments';
import Transcript from './Components/TranscriptSection/transcriptSection';

const EpisodePage = () => {
  const [isPlatformModalOpen, setIsPlatformModalOpen] = useState(false);

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '64px 24px',
    },
    header: {
      textAlign: 'center',
      marginBottom: '48px',
    },
    episodeNumber: {
      fontSize: '1.125rem',
      color: '#f97316',
      fontWeight: 500,
      marginBottom: '16px',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: 700,
      marginBottom: '16px',
      lineHeight: 1.2,
    },
    subtitle: {
      fontSize: '2rem',
      fontWeight: 600,
      color: '#374151',
      marginBottom: '16px',
      lineHeight: 1.2,
    },
    metadata: {
      fontSize: '0.875rem',
      color: '#6b7280',
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
    imageContainer: {
      marginBottom: '48px',
    },
    image: {
      width: '100%',
      height: 'auto',
      aspectRatio: '16/9',
      backgroundColor: '#f3f4f6',
      marginBottom: '24px',
    },
    playButton: {
      backgroundColor: '#f97316',
      color: 'white',
      padding: '16px 32px',
      border: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      margin: '0 auto',
      transition: 'background-color 0.2s',
      fontSize: '1rem',
      fontWeight: 500,
    },
    description: {
      color: '#374151',
      fontSize: '1.125rem',
      lineHeight: 1.7,
      marginBottom: '32px',
    },
    modal: {
      display: isPlatformModalOpen ? 'flex' : 'none',
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: 'white',
      padding: '32px',
      borderRadius: '8px',
      maxWidth: '400px',
      width: '100%',
    },
    modalTitle: {
      fontSize: '1.25rem',
      fontWeight: 600,
      marginBottom: '24px',
      textAlign: 'center',
    },
    platformButton: {
      width: '100%',
      padding: '16px',
      marginBottom: '12px',
      border: '1px solid #e5e7eb',
      borderRadius: '4px',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
      cursor: 'pointer',
      transition: 'background-color 0.2s',
      fontSize: '1rem',
      color: '#111827',
    },
  };

  const segments = [
    { time: '00:00', title: 'Introduction and background context' },
    { time: '15:30', title: 'Current situation in Gaza' },
    { time: '32:45', title: 'Irans role and regional implications' },
    { time: '48:20', title: 'Future scenarios and analysis' },
    { time: '01:12:30', title: 'Concluding thoughts and recommendations' },
  ];

  const platforms = [
    { name: 'YouTube', icon: Youtube, color: '#FF0000', link: '#' },
    { name: 'Spotify', icon: ExternalLink, color: '#1DB954', link: '#' },
    { name: 'Apple Podcasts', icon: ExternalLink, color: '#872EC4', link: '#' },
    { name: 'Tidal', icon: ExternalLink, color: '#000000', link: '#' },
    { name: 'SoundCloud', icon: ExternalLink, color: '#FF7700', link: '#' },
  ];

  const transcriptContent =  {
    "episodeTitle": "Middle East Break Down 2025",
    "episodeDate": "2025-01-08",
    "duration": 4360, // in seconds
    "sections": [
      {
        "sectionHeader": "Introduction & Background",
        "startTime": 0,
        "endTime": 325,
        "entries": [
          {
            "type": "speaker",
            "speaker": "Host",
            "text": "Welcome to another episode of Geopolitical Today...",
            "startTime": 0
          },
          {
            "type": "speaker",
            "speaker": "Dr. Dan Schueftan",
            "text": "Thank you for having me. To understand the current situation...",
            "startTime": 45
          },
          {
            "type": "note",
            "text": "Background context: 2024 regional conflicts timeline"
          }
        ]
      },
      {
        "sectionHeader": "Current Situation Analysis",
        "startTime": 326,
        "endTime": 1245,
        "entries": [
          {
            "type": "speaker",
            "speaker": "Host",
            "text": "Let's dive into the current military positions...",
            "startTime": 326
          }
        ]
      }
    ]
  }

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <div style={styles.episodeNumber}>Episode 364</div>
        <h1 style={styles.title}>Dr. Dan Schueftan:</h1>
        <h2 style={styles.subtitle}>Middle East Break Down 2025</h2>
        <div style={styles.metadata}>
          Published January 8, 2025 by naormeningher@gmail.com
        </div>
      </header>

      <div style={styles.imageContainer}>
        <div style={styles.image}></div>
        <button
          style={styles.playButton}
          onClick={() => setIsPlatformModalOpen(true)}
          onMouseEnter={e => (e.target.style.backgroundColor = '#ea580c')}
          onMouseLeave={e => (e.target.style.backgroundColor = '#f97316')}
        >
          <Play size={24} /> Play Episode
        </button>
      </div>

      <div style={styles.description}>
        A year ago, it felt like Israel was holding its breath. Hezbollah loomed in the north, Hamas
        continued to entrench itself in Gaza, and Assad's regime in Syria was as ruthless as ever. Iran,
        the puppet master of this, pulled the strings behind it all, funding and arming its proxies to
        encircle Israel. Fast forward to today, and the map looks vastly different. Gaza is in ruins,
        Assad is gone, and Hezbollah has taken a blow. Yet, with every gain comes a new set of
        challenges. We're still at war on multiple fronts, the Houthis are firing missiles almost daily,
        and so many Israelis are still held captive in Gaza.
      </div>

      <EpisodeSegments segments={segments} />

      {/* Platform Selection Modal */}
      <div style={styles.modal} onClick={() => setIsPlatformModalOpen(false)}>
        <div style={styles.modalContent} onClick={e => e.stopPropagation()}>
          <h3 style={styles.modalTitle}>Choose your platform</h3>
          {platforms.map((platform, index) => (
            <button
              key={index}
              style={styles.platformButton}
              onMouseEnter={e => {
                e.target.style.backgroundColor = '#f9fafb';
                e.target.style.borderColor = platform.color;
              }}
              onMouseLeave={e => {
                e.target.style.backgroundColor = 'white';
                e.target.style.borderColor = '#e5e7eb';
              }}
              onClick={() => window.open(platform.link, '_blank')}
            >
              <platform.icon size={20} color={platform.color} />
              Listen on {platform.name}
            </button>
          ))}
        </div>
      </div>

      <GuestBio />
      <EpisodeNotes />
      {/* Use the Transcript component here */}
      <Transcript transcriptContent={transcriptContent} />
      <EpisodeNavigation />
      <Comments />
      <NewsletterSignup />
    </div>
  );
};

export default EpisodePage;