import React, { useState, useEffect, useRef } from 'react';
import { Podcast, Clock, Globe } from 'lucide-react';

// Example usage within your AboutUs component:
const MetricsSection = () => {
  const [counts, setCounts] = useState({
    episodes: 0,
    minutes: 0,
    countries: 0
  });

  const sectionRef = useRef(null);
  const animationTriggered = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !animationTriggered.current) {
          animationTriggered.current = true;
          startCountAnimation();
        }
      },
      { threshold: 0.1 }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const startCountAnimation = () => {
    const duration = 2000; // 2 seconds
    const steps = 60;
    const interval = duration / steps;
    let step = 0;

    const timer = setInterval(() => {
      step++;
      const progress = step / steps;
      // Easing function for smoother animation
      const easeOutQuad = 1 - (1 - progress) * (1 - progress);

      setCounts({
        episodes: Math.min(Math.floor(150 * easeOutQuad), 150),
        minutes: Math.min(Math.floor(2000000 * easeOutQuad), 2000000),
        countries: Math.min(Math.floor(35 * easeOutQuad), 35)
      });

      if (step >= steps) {
        clearInterval(timer);
      }
    }, interval);
  };

  const formatNumber = (num) => {
    if (num >= 1000000) {
      return `${(num / 1000000).toFixed(1)}M`;
    }
    if (num >= 1000) {
      return `${(num / 1000).toFixed(0)}K`;
    }
    return num.toString();
  };

  return (
    <div ref={sectionRef} style={{
      margin: '64px 0',
      textAlign: 'center'
    }}>
      <h2 style={{
        fontSize: '1.75rem',
        fontWeight: 700,
        marginBottom: '24px'
      }}>
        Our Metrics
      </h2>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
        gap: '32px',
        margin: '64px 0',
        textAlign: 'center'
      }}>
        {[
          { 
            icon: <Podcast size={24} />,
            value: counts.episodes,
            label: 'Research Episodes',
            detail: 'In-depth analysis'
          },
          { 
            icon: <Clock size={24} />,
            value: counts.minutes,
            label: 'Minutes Listened',
            detail: 'Global engagement'
          },
          { 
            icon: <Globe size={24} />,
            value: counts.countries,
            label: 'Countries Reached',
            detail: 'International impact'
          }
        ].map((stat, index) => (
          <div 
            key={index}
            style={{
              padding: '32px',
              border: '1px solid #f3f4f6',
              borderRadius: '16px',
              transition: 'all 0.3s ease',
              backgroundColor: 'white'
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.transform = 'translateY(-4px)';
              e.currentTarget.style.borderColor = '#fde68a';
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.transform = 'none';
              e.currentTarget.style.borderColor = '#f3f4f6';
            }}
          >
            <div style={{
              fontSize: '2rem',
              fontWeight: 600,
              color: '#f97316',
              marginBottom: '8px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '12px'
            }}>
              {stat.icon}
              {formatNumber(stat.value)}+
            </div>
            <div style={{
              color: '#4b5563',
              fontSize: '0.875rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}>
              {stat.label}
            </div>
            <div style={{
              fontSize: '0.75rem',
              color: '#6b7280',
              marginTop: '4px'
            }}>
              {stat.detail}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MetricsSection;