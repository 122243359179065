import { useState } from "react";
import { MessageSquare } from 'lucide-react';

const Comments = () => {
  const [comment, setComment] = useState('');
  
  const styles = {
    container: {
      marginTop: '48px',
      borderTop: '1px solid #e5e7eb',
      paddingTop: '32px',
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 600,
      marginBottom: '24px',
    },
    form: {
      marginBottom: '32px',
    },
    input: {
      width: '100%',
      padding: '12px',
      border: '1px solid #e5e7eb',
      borderRadius: '4px',
      marginBottom: '16px',
      fontSize: '1rem',
    },
    button: {
      backgroundColor: '#f97316',
      color: 'white',
      padding: '12px 24px',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      transition: 'background-color 0.2s',
    },
    comment: {
      padding: '24px 0',
      borderBottom: '1px solid #e5e7eb',
    },
    commentHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '8px',
    },
    commentAuthor: {
      fontWeight: 500,
    },
    commentDate: {
      color: '#6b7280',
      fontSize: '0.875rem',
    },
    commentText: {
      color: '#374151',
      lineHeight: 1.6,
    },
  };

  return (
    <div style={styles.container}>
      <h3 style={styles.title}>Comments</h3>
      <div style={styles.form}>
        <textarea 
          style={styles.input} 
          rows={4}
          placeholder="Share your thoughts..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
        />
        <button 
          style={styles.button}
          onMouseEnter={e => e.target.style.backgroundColor = '#ea580c'}
          onMouseLeave={e => e.target.style.backgroundColor = '#f97316'}
        >
          <MessageSquare size={18} /> Post Comment
        </button>
      </div>
      
      <div style={styles.comment}>
        <div style={styles.commentHeader}>
          <span style={styles.commentAuthor}>Sarah Johnson</span>
          <span style={styles.commentDate}>2 days ago</span>
        </div>
        <p style={styles.commentText}>
          Fascinating analysis of the current situation. The historical context provided really helps 
          understand the complexities of the region.
        </p>
      </div>
    </div>
  );
};

export default Comments;