import React from 'react';
import { Microscope, Users, Award, BookOpen } from 'lucide-react';

const ResearchTimeline = () => {
  const milestones = [
    {
      year: '2020',
      title: 'Foundation & First Studies',
      description: 'Launched our first environmental health analysis series, establishing collaboration with leading research institutions.',
      icon: <Microscope size={16} />
    },
    {
      year: '2021',
      title: 'Growing Impact',
      description: 'Reached 100k monthly listeners. Our research was cited in major environmental policy discussions.',
      icon: <Users size={16} />
    },
    {
      year: '2023',
      title: 'International Recognition',
      description: 'Featured in Apple Podcasts Top Shows. Expanded coverage to global environmental health challenges.',
      icon: <Award size={16} />
    },
    {
      year: '2024',
      title: 'Research Innovation',
      description: 'Pioneered new methodologies in environmental exposure analysis. Launched collaborative research projects.',
      icon: <BookOpen size={16} />
    }
  ];

  const TimelineItem = ({ milestone, isLast }) => (
    <div 
      style={{
        position: 'relative',
        marginBottom: isLast ? '0' : '64px',
        paddingLeft: '48px',
        transition: 'transform 0.3s ease'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'translateX(8px)';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'translateX(0)';
      }}
    >
      {/* Year circle */}
      <div style={{
        position: 'absolute',
        left: '-9px',
        top: '0',
        width: '20px',
        height: '20px',
        backgroundColor: '#fff',
        border: '2px solid #f97316',
        borderRadius: '50%',
        zIndex: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        {milestone.icon}
      </div>

      {/* Content card */}
      <div style={{
        backgroundColor: '#fff',
        borderRadius: '16px',
        padding: '24px',
        transition: 'all 0.3s ease',
        position: 'relative'
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = '0 8px 16px rgba(0, 0, 0, 0.05)';
        e.currentTarget.style.borderColor = '#fde68a';
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = 'none';
        e.currentTarget.style.borderColor = '#f3f4f6';
      }}>
        <div style={{
          fontSize: '1.25rem',
          fontWeight: '600',
          color: '#f97316',
          marginBottom: '12px',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          {milestone.year}
        </div>
        
        <h4 style={{
          fontSize: '1.25rem',
          fontWeight: '600',
          color: '#111827',
          marginBottom: '12px',
          lineHeight: '1.4'
        }}>
          {milestone.title}
        </h4>
        
        <p style={{
          color: '#4b5563',
          fontSize: '0.975rem',
          lineHeight: '1.6',
          margin: '0'
        }}>
          {milestone.description}
        </p>
      </div>
    </div>
  );

  return (
    <div style={{
      margin: '80px 0',
      padding: '40px',
      backgroundColor: '#fff',
      borderRadius: '24px',
      position: 'relative'
    }}>
      <h3 style={{
        fontSize: '2rem',
        fontWeight: '700',
        marginBottom: '48px',
        textAlign: 'center',
        color: '#111827'
      }}>
        Our Research Journey
      </h3>
      
      {/* Timeline container */}
      <div style={{
        position: 'relative',
        maxWidth: '800px',
        margin: '0 auto',
        paddingLeft: '24px'
      }}>
        {/* Vertical line */}
        <div style={{
          position: 'absolute',
          left: '0',
          top: '0',
          bottom: '0',
          width: '2px',
          background: 'linear-gradient(to bottom, #f97316 0%, #fdba74 100%)',
          zIndex: 1
        }} />

        {milestones.map((milestone, index) => (
          <TimelineItem 
            key={index}
            milestone={milestone}
            isLast={index === milestones.length - 1}
          />
        ))}
      </div>
    </div>
  );
};

export default ResearchTimeline;